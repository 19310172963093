import styled, { css } from 'styled-components'

import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, LANDING_GRAY, WHITE } from '@/styles/colors'
import { goldman, lato } from '@/utils/fonts'

export const OfficialPartnersWrapper = styled.section<{
  $withoutDecoration: boolean
}>`
  position: relative;
  margin-top: 48px;
  ${({ $withoutDecoration }) =>
    $withoutDecoration &&
    css`
      margin-top: 0;
    `}
`

export const StrippedDecoration = styled.div`
  position: absolute;
  left: -10%;
  top: -40px;
  width: 560px;
  height: 130px;
  background: transparent
    repeating-linear-gradient(
      90deg,
      #232323,
      #232323 2px,
      transparent 2px,
      transparent 42px
    );
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OfficialPartnersLogo = styled(Image)`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

export const OfficialPartnersContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  background-color: ${LANDING_GRAY};
  border-radius: 6px;
  border: 1px solid #2e2e2e;
  overflow: hidden;
  min-height: 302px;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`

export const OfficialPartnersContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 22px;
  z-index: 2;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 32px 24px 40px;
  grid-column: 2;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    padding: 41px 57px;
  }
`

export const OfficialPartnersHeading = styled.h2`
  font-family: ${goldman.style.fontFamily};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: ${WHITE};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const OfficialPartnersText = styled.p`
  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${GRAY.x400};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const MobileGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(0deg, #171717 0, rgba(115, 115, 115, 0) 100%);

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    display: none;
  }
`
export const OfficialPartnersTeam = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  order: 1;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    order: 2;
    overflow: visible;
  }
`

const BaseImage = styled(Image)`
  position: absolute;
  height: 130%;
  width: auto;
  top: 5%;
  transform: translateX(-50%);
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    height: 150%;
    top: -20%;
  }
`

export const ImageMaj3r = styled(BaseImage)`
  left: 50%;
  height: 140%;
  top: -2%;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    height: 155%;
    top: -20%;
  }
`
export const ImageCalyx = styled(BaseImage)`
  left: 50%;
  margin-left: 95px;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    margin-left: 280px;
  }
`
export const ImageWoxic = styled(BaseImage)`
  left: 50%;
  margin-left: -100px;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    margin-left: -300px;
  }
`

const BaseDecorationalBlock = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 12px;
  background-color: #908464;
  position: absolute;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    width: 144px;
    height: 144px;
  }
`

export const DecorationalBlock1 = styled(BaseDecorationalBlock)`
  left: -25px;
  top: 25%;
  width: 52px;
  height: 52px;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    width: 127px;
    height: 193px;
    left: 20%;
    bottom: -6%;
    top: auto;
  }
`

export const DecorationalBlock2 = styled(BaseDecorationalBlock)`
  right: -33px;
  top: -33px;
`

export const LogoWrapper = styled.div`
  width: 106px;
  height: 69px;
  background: #28282a;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
`

export const LogosContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;

  svg {
    color: white;
  }
`

export const TeamImage = styled(Image)`
  position: absolute;
  height: 100%;
  bottom: 0;
  width: auto;
  object-fit: contain;
  z-index: 1;

  &:first-of-type {
    left: -4%;
    bottom: 0;
    width: auto;
    height: 105%;
  }

  &:last-of-type {
    right: -9%;
    top: -12%;
    width: auto;
    height: 140%;
  }

  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    &:first-of-type {
      left: -2%;
      bottom: -8%;
      height: 120%;
    }

    &:last-of-type {
      right: -5%;
      bottom: -30%;
      height: 160%;
    }
  }

  @media (max-width: ${BREAKPOINTS_PX.xl}) {
    display: none;
  }
`
